import EuroIcon from '@mui/icons-material/Euro';
import {
    Badge,
    Box,
    Button,
    CardMedia,
    Checkbox,
    Divider,
    FormControl,
    FormControlLabel,
    FormGroup,
    Grid,
    Radio,
    RadioGroup,
    Stack,
    Tooltip,
    Typography,
    useMediaQuery
} from '@mui/material';
import menuApi from 'api/menu';
import { ArrowBackIcon, ArrowLeftIcon, ArrowRightIcon, InfoIcon } from 'assets/mui/MuiIcons';
import CustomIconButton from 'components/Button/CustomIconButton';
import GroupedItemButtons from 'components/Button/GroupedItemButtons';
import CustomChip from 'components/Chip/CustomChip';
import CustomSwippableDrawer from 'components/Drawer/CustomSwippableDrawer';
import LoadingSkeleton from 'components/Loading/LoadingSkeleton';
import CustomTextArea from 'components/Text/CustomTextArea';
import CustomTypography from 'components/Typography/CustomTypography';
import CustomTypographyWithIcon from 'components/Typography/CustomTypographyWithIcon';
import { outletBackBtnIcon } from 'constants/defaultTheme';
import { requestTextFieldLimit } from 'constants/textFieldLimit';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Carousel from 'react-material-ui-carousel';
import { ItemPaper, autDrawerPaperStyle, authBackBtnStyle, cartButtonLabelItemStyle, cartButtonStackStyle, dividerStyle, itemNutrientCardTextStyle, itemNutrientDetailGramsTitleStyle, itemNutrientDetailTextStyle, itemNutrientDetailTitleStyle, itemNutrientSubDetailTitleStyle, itemNutrientText, justifyContentCenter, lumanosimoTitleStyle, menuItemButtonStyle, menuItemDescriptionHeaderTextStyle, menuItemHeaderTextStyle, menuItemPriceHeaderTextStyle, menuItemPriceIconStyle, requestTextareaBoxStyle, textAlignLeft, textAlignRight } from 'themes/defaultThemes';
import { formValChangeWithParentElementWithNameAndValue, replaceLastTwoChars } from 'utils/functions';
import { getAllergies } from 'utils/functions/allergy';
import { getItemPhoto } from 'utils/functions/assets';
import { getExtraIcons } from 'utils/functions/extraIcon';
import { findCurrency } from 'utils/functions/order';
import { getLanguageFromURL } from 'utils/language';

/**
 * The CustomItemCard, that display a item pop-up card
 * 
 * @param {boolean} isOpen If the values is `true`, the modal should be open and visible.
 * @param {string} id the dialog id
 * @param {string} title the dialog title
 * @param {string} message the dialog message
 * @param {string} cancelLabel the dialog cancel label
 * @param {string} actionLabel the dialog action label
 * @param {function} action the function that handles the dialog action button click
 * @param {function} handleOpen the function that handles the dialog open state
 *
 * @returns {JSX.Element} A React component that renders a dialog to view item details.
 * 
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function CustomItemCard({
    organizationID,
    outletAllowComments = true,
    outletURL,
    menuConfiguration,
    itemID,
    isOpen = false,
    handleOpen,
    iconPositionStart = false,
    action,
    enableCart,
    currency = "EURO",
    cartIndex = -1,
    cartItemDefault,
    extraDetails
}) {
    const isMobile = useMediaQuery('(max-width:468px)');
    const { t } = useTranslation();
    const [item, setItem] = useState(isOpen);
    const [modifierListInvalid, setModifierListInvalid] = useState([]);

    const [isLoaded, setIsLoaded] = useState(false);

    const [modifiersPrice, setModifiersPrice] = useState(0);
    const [cartItem, setCartItem] = useState((cartIndex !== null && cartIndex !== -1) ? cartItemDefault : {
        id: itemID,
        name: "",
        quantity: 1,
        notes: "",
        price: 0,
        totalPrice: 0,
        pvcRecyclingTax: 0,
        loyaltyPointsEarned: 0,
        modifierListIds: [],
        ...extraDetails
    });

    const [arrayOfPhotos, setArrayOfPhotos] = useState([]);
    const [viewNutrients, setViewNutrients] = useState(false);

    useEffect(() => {
        getMenuItemDetails();
    }, []);

    function prepareText(viewdModifierList) {
        let modifierListText = '';
        modifierListText = cartItem.modifierListIds?.find((modifierList) => modifierList.modifierListId === viewdModifierList?.id)?.modifiers?.map((modifier) => modifier.name);

        let modifiersText = modifierListText?.slice(0, -1)?.join(', ') + (modifierListText?.length > 1 ? ', ' : '') + modifierListText?.slice(-1);
        if (modifiersText === "" || modifiersText === null) {
            modifiersText = prepareTextChip(modifiersText, viewdModifierList);
        }
        return modifiersText;
    }

    function prepareTextChip(modifiersText, viewdModifierList) {
        let chipText = t('item.requiredChoice');
        if (!viewdModifierList.allowMultipleSelection)
            chipText = t('item.requiredChoice');
        else if (viewdModifierList.allowMultipleSelection && viewdModifierList.minModifiers > 0)
            chipText = t('item.requiredChoice');
        else if (viewdModifierList.allowMultipleSelection && (viewdModifierList.minModifiers === 0 || viewdModifierList.minModifiers === null))
            chipText = t('item.optionalChoice');

        modifiersText = <>
            <CustomChip
                label={chipText}
                sx={{
                    background: replaceLastTwoChars(menuConfiguration?.chipTextColor, "1c"),
                    color: menuConfiguration?.chipTextColor ? menuConfiguration.chipTextColor : "47a6d21",
                    fontSize: `calc(${menuConfiguration?.itemHeaderFontSize} - 6px)`,
                    fontWeight: "bold",
                    padding: "2px 5px"
                }} />
            {chipText === t('item.requiredChoice') &&
                <CustomChip
                    label={
                        <>
                            <CustomTypography
                                variant="span"
                                text={`${t('item.min')}: `}
                                sx={{ fontWeight: "bold", fontSize: `calc(${menuConfiguration?.itemHeaderFontSize} - 6px)` }}
                            />
                            <CustomTypography
                                variant="span"
                                text={viewdModifierList?.minModifiers !== null ? viewdModifierList.minModifiers : 0}
                                sx={{ fontSize: `calc(${menuConfiguration?.itemHeaderFontSize} - 6px)` }}
                            />
                        </>
                    }
                    sx={{
                        background: replaceLastTwoChars(menuConfiguration.chipTextColor, "1c"),
                        color: menuConfiguration?.chipTextColor ? menuConfiguration.chipTextColor : "47a6d21"
                    }}
                />
            }
            {viewdModifierList.allowMultipleSelection && viewdModifierList?.maxModifiers > 0 &&
                <CustomChip
                    label={
                        <>
                            <CustomTypography
                                variant="span"
                                text={`${t('item.max')}: `}
                                sx={{ fontWeight: "bold", fontSize: `calc(${menuConfiguration?.itemHeaderFontSize} - 6px)` }}
                            />
                            <CustomTypography
                                variant="span"
                                text={viewdModifierList?.maxModifiers !== null ? viewdModifierList.maxModifiers : 0}
                                sx={{ fontSize: `calc(${menuConfiguration?.itemHeaderFontSize} - 6px)` }}
                            />
                        </>
                    }
                    sx={{
                        background: replaceLastTwoChars(menuConfiguration?.chipTextColor, "1c"),
                        color: menuConfiguration?.chipTextColor ? menuConfiguration.chipTextColor : "47a6d21"
                    }}
                />
            }
        </>

        return modifiersText;
    }

    /**
     * Function that initializes the modifierLists for cart item.
     * @param {*} item 
     */
    function initCartItem(itemDet) {
        let modifierLists = [];
        let modifiersPrice = 0; // Initialize modifiersPrice
        itemDet.modifierLists.map((modifierList) => {
            // Initialize an array to hold the modifiers for this list
            if (modifierList.modifiers !== null && modifierList.modifiers.length > 0) {
                let modifiers = [];

                if (modifierList.allowMultipleSelection) {
                    // If multiple selection is allowed, don't add any default modifiers
                    modifiers = [];
                } else {
                    // If only single selection is allowed, add the first modifier
                    let firstModifier = {
                        id: modifierList.modifiers[0]?.id,
                        name: modifierList.modifiers[0]?.name,
                        nameEn: modifierList.modifiers[0]?.nameEn,
                        price: modifierList.modifiers[0]?.price
                    };
                    modifiers.push(firstModifier);

                    // Add the price of the first modifier to modifiersPrice
                    modifiersPrice += firstModifier.price;
                }

                // create invalid record 
                modifierListInvalid.push({ id: modifierList.id, invalidText: "" });

                // Push the modifier list with its modifiers to modifierLists
                modifierLists.push({
                    modifierListId: modifierList.id,
                    name: modifierList.name,
                    nameEN: modifierList.nameEN,
                    modifiers: modifiers,
                    maxModifiers: modifierList.maxModifiers,
                    minModifiers: modifierList.minModifiers,
                    allowMultipleSelection: modifierList.allowMultipleSelection
                });
            }
        });

        if (cartIndex === null || cartIndex === -1) {
            // Set cart item with totalPrice including modifiersPrice
            setCartItem({
                id: itemDet.id,
                name: itemDet.name,
                nameEN: itemDet.nameEN,
                quantity: 1,
                notes: "",
                price: itemDet.price,
                totalPrice: itemDet.price + modifiersPrice,
                pvcRecyclingTax: itemDet.pvcRecyclingTax,
                modifierListIds: modifierLists,
                loyaltyPointsEarned: itemDet.loyaltyPoints,
                itemCategories: itemDet.itemCategories,
                ...extraDetails
            });
        } else {
            setCartItem(cartItemDefault)
        }
    }

    /**
   * The rest endpoint to get the menu page details.
   */
    function getMenuItemDetails() {
        menuApi.fetchItemDetails(outletURL, itemID, getLanguageFromURL()).then((r) => {
            Promise.resolve()
                .then(() => {
                    setItem(r.data.returnobject);
                    let photos = [];

                    r.data.returnobject?.photos?.map((photo) =>
                        photos.push(
                            <CardMedia
                                sx={{
                                    height: 340,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "auto",
                                }}
                                image={getItemPhoto(organizationID, itemID, photo)}
                                title={item.name}
                            />
                        )
                    )
                    setArrayOfPhotos(photos);
                    //init form for single selection 
                    initCartItem(r.data.returnobject);
                })
                .then(() => {
                    setIsLoaded(true);
                })
        }).catch((e) => {
        });
    }

    /**
     * Callback that handles the counter increment.
     * 
     * @param {*} counter the old counter value
     */
    function handleIncrement(counter) {
        setCartItem({ ...cartItem, quantity: counter + 1 });
    }

    /**
     * Callback that handles the counter decrement.
     * 
     * @param {*} counter the old counter value
     */
    function handleDecrement(counter) {
        setCartItem({ ...cartItem, quantity: counter - 1 });
    }

    function handleSingleSelectionChange(modifierListId, modifierId) {
        let prevModifierPrice = 0;
        let newModifierPrice = item.modifierLists.find(modifierList => modifierList.id === modifierListId).modifiers.find(modifier => modifier.id === modifierId).price;

        setCartItem(prevState => {
            // Find the previous modifier price
            const currentModifierList = prevState.modifierListIds.find(ml => ml.modifierListId === modifierListId);
            if (currentModifierList && currentModifierList.modifiers.length > 0) {
                const currentModifier = currentModifierList.modifiers[0];
                prevModifierPrice = currentModifier.price;
            }

            // Update the state with the new modifier
            const newModifierListIds = prevState.modifierListIds.map(modifierList =>
                modifierList.modifierListId === modifierListId ? {
                    ...modifierList,
                    modifiers: [{
                        id: modifierId,
                        name: item.modifierLists.find(modifierList => modifierList.id === modifierListId).modifiers.find(modifier => modifier.id === modifierId).name,
                        nameEn: item.modifierLists.find(modifierList => modifierList.id === modifierListId).modifiers.find(modifier => modifier.id === modifierId).nameEn,
                        price: newModifierPrice,
                    }]
                } : modifierList
            );

            // Update total price
            const updatedTotalPrice = prevState.totalPrice - prevModifierPrice + newModifierPrice;

            return {
                ...prevState,
                modifierListIds: newModifierListIds,
                totalPrice: updatedTotalPrice
            };
        });
    };

    function handleMultipleSelectionChange(modifierListId, modifierId, modifiers) {
        setCartItem(prevState => {
            let prevModifierPrice = 0;
            let newModifierPrice = modifiers.find(modifier => modifier.id === modifierId).price;

            // Find the previous modifier price
            const currentModifierList = prevState.modifierListIds.find(ml => ml.modifierListId === modifierListId);
            if (currentModifierList) {
                currentModifierList.modifiers.forEach(modifier => {
                    if (modifier.id === modifierId) {
                        prevModifierPrice = modifier.price;
                    }
                });
            }

            // Update the state with the new modifier
            const newModifierListIds = prevState.modifierListIds.map(modifierList => {
                if (modifierList.modifierListId === modifierListId) {
                    const isExistingModifier = modifierList.modifiers.some(mod => mod.id === modifierId);

                    // Check if maxModifiers is 1 and a new modifier is selected
                    if (modifierList.maxModifiers === 1) {
                        if (isExistingModifier) {
                            // Replace the existing modifier with the new one
                            return {
                                ...modifierList,
                                modifiers: []
                            };
                        } else {
                            // Only keep the new modifier
                            return {
                                ...modifierList,
                                modifiers: [{
                                    id: modifierId,
                                    name: modifiers.find(modifier => modifier.id === modifierId).name,
                                    nameEn: modifiers.find(modifier => modifier.id === modifierId).nameEn,
                                    price: newModifierPrice,
                                    maxModifiers: modifierList.maxModifiers,
                                    minModifiers: modifierList.minModifiers
                                }]
                            };
                        }
                    } else {
                        let validateMultipleChoiceRules = (modifierList.modifiers.length + (isExistingModifier ? (-1) : 1)) <= (item.modifierLists.find((modifierListObj) => modifierListObj.id === modifierListId)?.maxModifiers);

                        if (validateMultipleChoiceRules === undefined || validateMultipleChoiceRules) {
                            return {
                                ...modifierList,
                                modifiers: isExistingModifier ?
                                    modifierList.modifiers.filter(mod => mod.id !== modifierId) :
                                    [...modifierList.modifiers, {
                                        id: modifierId,
                                        name: modifiers.find(modifier => modifier.id === modifierId).name,
                                        nameEn: modifiers.find(modifier => modifier.id === modifierId).nameEn,
                                        price: newModifierPrice,
                                        maxModifiers: modifierList.maxModifiers,
                                        minModifiers: modifierList.minModifiers
                                    }]
                            };
                        } else {
                            return modifierList;
                        }
                    }
                } else {
                    return modifierList;
                }
            });

            // Update total price
            const updatedTotalPrice = prevState.totalPrice - prevModifierPrice + newModifierPrice;
            return {
                ...prevState,
                modifierListIds: newModifierListIds,
                totalPrice: updatedTotalPrice
            };
        });
    }

    /**
     * Function that handles the item itemDialog open or close state.
     * @property {boolean} isOpen If the values is `true`, the itemDialog should be open and visible.
     * @property {object} modifierList the item modifiers to be displayed
     */
    function handleModifiersDialogState(isOpen, modifierList) {
        setDialog({
            ...dialog,
            isOpen: isOpen,
            modifierList: modifierList
        });
    }

    // pop dialog for select modifiers
    const [dialog, setDialog] = useState({
        isOpen: false,
        modifierList: null
    });

    // ============================= RENDER

    if (!isLoaded)
        return (
            <LoadingSkeleton lines={9} />
        )
    else {
        return (
            <Grid container>
                {viewNutrients &&
                    <Grid item xs={12} md={12}>
                        <Grid container>
                            <Grid item xs={12} md={12} sx={textAlignLeft()}>
                                <CustomIconButton
                                    icon={<ArrowBackIcon />}
                                    sx={authBackBtnStyle()}
                                    onClick={() => setViewNutrients(false)}
                                    label={undefined}
                                />
                            </Grid>

                            <Grid item xs={12} md={12} >
                                <CustomTypography
                                    variant="body2"
                                    text={t('item.nutrients.label')}
                                    sx={{ ...menuItemHeaderTextStyle({ fontSize: menuConfiguration?.itemHeaderFontSize, color: menuConfiguration?.itemHeaderColor }), ...lumanosimoTitleStyle() }}
                                />
                            </Grid>

                            <Grid item xs={12} md={12} sx={{ ...textAlignLeft(), padding: "10px" }}>
                                <Grid container>
                                    <Grid container xs={5} spacing={1.65} direction="column">
                                        <Grid item xs={1}>
                                            <CustomTypography
                                                variant="body2"
                                                sx={{ ...itemNutrientDetailTitleStyle(), paddingTop: "30px" }}
                                                text={""} />
                                        </Grid>
                                        <Grid item xs={1}>
                                            <CustomTypography
                                                variant="body2"
                                                sx={itemNutrientDetailTitleStyle()}
                                                text={t('item.nutrients.energy')} />
                                        </Grid>
                                        <Grid item xs={1}>
                                            <CustomTypography
                                                variant="body2"
                                                sx={itemNutrientDetailTitleStyle()}
                                                text={t('item.nutrients.calories')} />
                                        </Grid>
                                        <Grid item xs={1}>
                                            <CustomTypography
                                                variant="body2"
                                                sx={itemNutrientDetailTitleStyle()}
                                                text={t('item.nutrients.fat')} />
                                        </Grid>
                                        <Grid item xs={1}>
                                            <CustomTypography
                                                variant="body2"
                                                sx={itemNutrientSubDetailTitleStyle()}
                                                text={t('item.nutrients.sat_fatDetailsLabel')} />
                                        </Grid>
                                        <Grid item xs={1}>
                                            <CustomTypography
                                                variant="body2"
                                                sx={itemNutrientSubDetailTitleStyle()}
                                                text={t('item.nutrients.trans_fatDetailsLabel')} />
                                        </Grid>
                                        <Grid item xs={1}>
                                            <CustomTypography
                                                variant="body2"
                                                sx={itemNutrientDetailTitleStyle()}
                                                text={t('item.nutrients.carb')} />
                                        </Grid>
                                        <Grid item xs={1}>
                                            <CustomTypography
                                                variant="body2"
                                                sx={itemNutrientSubDetailTitleStyle()}
                                                text={t('item.nutrients.sugarsDetailsLabel')} />
                                        </Grid>
                                        <Grid item xs={1}>
                                            <CustomTypography
                                                variant="body2"
                                                sx={itemNutrientSubDetailTitleStyle()}
                                                text={t('item.nutrients.fiberDetailsLabel')} />
                                        </Grid>
                                        <Grid item xs={1}>
                                            <CustomTypography
                                                variant="body2"
                                                sx={itemNutrientDetailTitleStyle()}
                                                text={t('item.nutrients.protein')} />
                                        </Grid>
                                        <Grid item xs={1}>
                                            <CustomTypography
                                                variant="body2"
                                                sx={itemNutrientDetailTitleStyle()}
                                                text={t('item.nutrients.salt')} />
                                        </Grid>
                                        {/* <Grid item xs={1}>
                                                <CustomTypography
                                        variant="body2"
                                        text={t('item.nutrients.sodium')}/>
                                            </Grid>
                                            <Grid item xs={1}>
                                                <CustomTypography
                                        variant="body2"
                                        text={t('item.nutrients.vitaminA')}/>
                                            </Grid>
                                            <Grid item xs={1}>
                                                <CustomTypography
                                        variant="body2"
                                        text={t('item.nutrients.vitaminC')}/>
                                            </Grid>
                                            <Grid item xs={1}>
                                                <CustomTypography
                                        variant="body2"
                                        text={t('item.nutrients.calcium')}/>
                                            </Grid>
                                            <Grid item xs={1}>
                                                <CustomTypography
                                        variant="body2"
                                        text={t('item.nutrients.iron')}/>
                                            </Grid> */}
                                    </Grid>

                                    {item.nutrients.map((nutrient) =>
                                        <Grid container xs={3} spacing={2} direction="column">
                                            <Grid item xs={1}>
                                                <CustomTypography
                                                    variant="body2"
                                                    sx={itemNutrientDetailGramsTitleStyle()}
                                                    text={`${t('item.nutrients.gramsDetailsLabel')} ${nutrient.grams} ${(nutrient.grams > -1) ? t('item.nutrients.gramsAfterLabel') : t('item.nutrients.gramsPortionLabel')} `} />
                                            </Grid>
                                            <Grid item xs={1}>
                                                <CustomTypography
                                                    variant="body2"
                                                    sx={itemNutrientDetailTextStyle()}
                                                    text={`${nutrient.energy} ${(nutrient.energy > -1) ? t('item.nutrients.enrgeyAfterLabel') : "-"} `} />
                                            </Grid>
                                            <Grid item xs={1}>
                                                <CustomTypography
                                                    variant="body2"
                                                    sx={itemNutrientDetailTextStyle()}
                                                    text={`${nutrient.calories} ${(nutrient.calories > -1) ? t('item.nutrients.caloriesAfterLabel') : "-"} `} />
                                            </Grid>
                                            <Grid item xs={1}>
                                                <CustomTypography
                                                    variant="body2"
                                                    sx={itemNutrientDetailTextStyle()}
                                                    text={`${nutrient.fat} ${(nutrient.fat > -1) ? t('item.nutrients.fatAfterLabel') : "-"} `} />
                                            </Grid>
                                            <Grid item xs={1}>
                                                <CustomTypography
                                                    variant="body2"
                                                    sx={itemNutrientDetailTextStyle()}
                                                    text={`${nutrient.sat_fat} ${(nutrient.sat_fat > -1) ? t('item.nutrients.sat_fatAfterLabel') : "-"} `} />
                                            </Grid>
                                            <Grid item xs={1}>
                                                <CustomTypography
                                                    variant="body2"
                                                    sx={itemNutrientDetailTextStyle()}
                                                    text={`${nutrient.trans_fat} ${(nutrient.trans_fat > -1) ? t('item.nutrients.trans_fatAfterLabel') : "-"} `} />
                                            </Grid>
                                            <Grid item xs={1}>
                                                <CustomTypography
                                                    variant="body2"
                                                    sx={itemNutrientDetailTextStyle()}
                                                    text={`${nutrient.carb} ${(nutrient.carb > -1) ? t('item.nutrients.carbAfterLabel') : "-"} `} />
                                            </Grid>
                                            <Grid item xs={1}>
                                                <CustomTypography
                                                    variant="body2"
                                                    sx={itemNutrientDetailTextStyle()}
                                                    text={`${nutrient.sugars} ${(nutrient.sugars > -1) ? t('item.nutrients.sugarsAfterLabel') : "-"} `} />
                                            </Grid>
                                            <Grid item xs={1}>
                                                <CustomTypography
                                                    variant="body2"
                                                    sx={itemNutrientDetailTextStyle()}
                                                    text={`${nutrient.fiber} ${(nutrient.fiber > -1) ? t('item.nutrients.fiberAfterLabel') : "-"} `} />
                                            </Grid>
                                            <Grid item xs={1}>
                                                <CustomTypography
                                                    variant="body2"
                                                    sx={itemNutrientDetailTextStyle()}
                                                    text={`${nutrient.protein} ${(nutrient.protein > -1) ? t('item.nutrients.proteinAfterLabel') : "-"} `} />
                                            </Grid>
                                            <Grid item xs={1}>
                                                <CustomTypography
                                                    variant="body2"
                                                    sx={itemNutrientDetailTextStyle()}
                                                    text={`${nutrient.salt} ${(nutrient.salt > -1) ? t('item.nutrients.saltAfterLabel') : "-"} `} />
                                            </Grid>
                                            {/* 
                                            <Grid item xs={1}>
                                                <CustomTypography
                                                    variant="body2"
                                                    sx={itemNutrientDetailTextStyle()}
                                                    text={`${nutrient.sodium} ${(nutrient.sodium > -1) ? t('item.nutrients.sodiumAfterLabel') : "-"} `} />
                                            </Grid>
                                            <Grid item xs={1}>
                                                <CustomTypography
                                                    variant="body2"
                                                    sx={itemNutrientDetailTextStyle()}
                                                    text={`${nutrient.vitaminA} ${(nutrient.vitaminA > -1) ? t('item.nutrients.vitaminAAfterLabel') : "-"} `} />
                                            </Grid>
                                            <Grid item xs={1}>
                                                <CustomTypography
                                                    variant="body2"
                                                    sx={itemNutrientDetailTextStyle()}
                                                    text={t('item.nutrients.vitaminC')} />
                                                    text={`${nutrient.vitaminA} ${(nutrient.vitaminA > -1) ? t('item.nutrients.vitaminAAfterLabel') : "-"} `} />
                                            </Grid>
                                            <Grid item xs={1}>
                                                <CustomTypography
                                                    variant="body2"
                                                    sx={itemNutrientDetailTextStyle()}
                                                    text={t('item.nutrients.calcium')} />
                                                    text={`${nutrient.calcium} ${(nutrient.calcium > -1) ? t('item.nutrients.calciumAAfterLabel') : "-"} `} />
                                            </Grid>
                                            <Grid item xs={1}>
                                                <CustomTypography
                                                    variant="body2"
                                                    sx={itemNutrientDetailTextStyle()}
                                                    text={`${nutrient.iron} ${(nutrient.iron > -1) ? t('item.nutrients.ironAAfterLabel') : "-"} `} />
                                            </Grid>
                                        */}
                                        </Grid>
                                    )}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                }

                {!viewNutrients &&
                    <>
                        <ArrowBackIcon onClick={() => handleOpen(false)}  sx={outletBackBtnIcon()} />
                        {arrayOfPhotos?.length === 1 &&
                            <Grid item xs={12} md={12}>
                                {arrayOfPhotos[0]}
                            </Grid>
                        }

                        {arrayOfPhotos?.length > 1 &&
                            <Grid item xs={12} md={12}>
                                <Carousel
                                    animation="slide"
                                    swipe={true}
                                    autoPlay={false}
                                    navButtonsAlwaysVisible={true}
                                    cycleNavigation={true}
                                    fullHeightHover={false}     // We want the nav buttons wrapper to only be as big as the button element is
                                    navButtonsProps={{          // Change the colors and radius of the actual buttons. THIS STYLES BOTH BUTTONS
                                        style: {
                                            borderRadius: 0,
                                            background: '#bdbdbd94',
                                            borderRadius: "30px",
                                            color: "black"
                                        }
                                    }}
                                    navButtonsWrapperProps={{   // Move the buttons to the bottom. Unsetting top here to override default style.
                                        style: {
                                            bottom: '0'
                                        }
                                    }}
                                    NextIcon={<ArrowRightIcon />}             // Change the "inside" of the next button 
                                    PrevIcon={<ArrowLeftIcon />}             // Change the "inside of the prev button
                                >
                                    {arrayOfPhotos}
                                </Carousel>
                            </Grid>
                        }

                        <Grid item xs={12} md={12} sx={{marginTop: "30px"}}>
                            <Grid container sx={{ background: "white", padding: "10px" }}>
                                <Grid item xs={12} md={12} sx={{ ...textAlignLeft(), marginTop: item.photos?.length > 0 ? "20px" : 0 }}>
                                    {getExtraIcons(item?.extraIcons)}
                                    <CustomTypography
                                        variant="body2"
                                        text={item.name}
                                        sx={menuItemHeaderTextStyle({ fontSize: menuConfiguration?.itemHeaderFontSize, color: menuConfiguration?.itemHeaderColor })}
                                    />
                                </Grid>

                                <Grid item xs={12} md={12} sx={textAlignLeft()}>
                                    {item.description?.length > 0 &&
                                        <CustomTypography
                                            variant="body2"
                                            text={item.description}
                                            sx={menuItemDescriptionHeaderTextStyle({ fontSize: menuConfiguration?.itemDescFontSize, color: menuConfiguration?.itemDescColor })}
                                        />
                                    }
                                </Grid>

                                <Grid item xs={6} md={6} sx={textAlignLeft()}>
                                    <CustomTypographyWithIcon
                                        variant="body2"
                                        text={cartItem?.totalPrice}
                                        sx={menuItemPriceHeaderTextStyle({ fontSize: menuConfiguration?.itemPriceFontSize, color: menuConfiguration?.itemPriceColor })}
                                        icon={<EuroIcon sx={menuItemPriceIconStyle({ fontSize: menuConfiguration?.itemPriceFontSize, color: menuConfiguration?.itemPriceColor })} />}
                                        iconPositionStart={iconPositionStart}
                                    />
                                </Grid>

                                <Grid item xs={6} md={6} sx={textAlignRight()}>
                                    {item.pvcRecyclingTax > 0 &&
                                        <CustomChip
                                            label={`${t('item.pvcRecyclingTax')}: ${item.pvcRecyclingTax} ${findCurrency(currency)}`}
                                            sx={{
                                                background: replaceLastTwoChars(menuConfiguration.chipTextColor, "1c"),
                                                color: menuConfiguration.chipTextColor ? menuConfiguration.chipTextColor : "47a6d21"
                                            }} />
                                    }
                                    {item.loyaltyPoints > 0 && menuConfiguration?.showPointsPerItem &&
                                        <CustomChip
                                            label={`${item.loyaltyPoints} ${t('item.points')}`}
                                            sx={{
                                                background: replaceLastTwoChars(menuConfiguration.chipTextColor, "1c"),
                                                color: menuConfiguration.chipTextColor ? menuConfiguration.chipTextColor : "47a6d21"
                                            }} />
                                    }
                                </Grid>

                                {item.nutrients?.length === 1 &&
                                    <Grid item xs={12} sm={12} sx={{ background: "lightgrey", borderRadius: "20px", padding: "10px" }} onClick={() => setViewNutrients(true)}>
                                        <Grid container sx={justifyContentCenter()}>
                                            <Grid item>
                                                <CustomTypography
                                                    variant="body2"
                                                    text={t('item.nutrients.label')}
                                                    sx={{ ...menuItemHeaderTextStyle({ fontSize: menuConfiguration?.itemHeaderFontSize, color: menuConfiguration?.itemHeaderColor }), ...lumanosimoTitleStyle() }}
                                                />
                                            </Grid>
                                            <Grid item sx={{ paddingLeft: "10px" }}>
                                                <InfoIcon sx={{ fontSize: "15px" }} />
                                            </Grid>
                                        </Grid>

                                        {/* <Divider light flexItem sx={dividerStyle()} /> */}
                                        <Grid container gap={1} sx={{ ...textAlignLeft(), margin: "10px" }}>
                                            <Grid item xs={2.5} md={3} sx={itemNutrientCardTextStyle()} >
                                                <Grid container>
                                                    <Grid item xs={12} md={12}>
                                                        <CustomTypography variant="span" text={t('item.caloriesShort')} sx={itemNutrientText()} />
                                                    </Grid>
                                                    <Grid item xs={12} md={12}>
                                                        <CustomTypography variant="span" text={`${item.nutrients[0].calories} ${t('item.nutrients.caloriesAfterLabel')}`} />
                                                    </Grid>
                                                </Grid>
                                            </Grid>

                                            <Grid item xs={2.5} md={3} sx={itemNutrientCardTextStyle()}>
                                                <Grid container>
                                                    <Grid item xs={12} md={12}>
                                                        <CustomTypography variant="span" text={t('item.proteinShort')} sx={itemNutrientText()} />
                                                    </Grid>
                                                    <Grid item xs={12} md={12}>
                                                        <CustomTypography variant="span" text={`${item.nutrients[0].protein} ${t('item.nutrients.proteinAfterLabel')}`} />
                                                    </Grid>
                                                </Grid>
                                            </Grid>

                                            <Grid item xs={2.5} md={3} sx={itemNutrientCardTextStyle()}>
                                                <Grid container>
                                                    <Grid item xs={12} md={12}>
                                                        <CustomTypography variant="span" text={t('item.nutrients.fat')} sx={itemNutrientText()} />
                                                    </Grid>
                                                    <Grid item xs={12} md={12}>
                                                        <CustomTypography variant="span" text={`${item.nutrients[0].fat} ${t('item.nutrients.fatAfterLabel')}`} />
                                                    </Grid>
                                                </Grid>
                                            </Grid>

                                            <Grid item xs={2.5} md={3} sx={itemNutrientCardTextStyle()}>
                                                <Grid container>
                                                    <Grid item xs={12} md={12}>
                                                        <CustomTypography variant="span" text={t('item.carbShort')} sx={itemNutrientText()} />
                                                    </Grid>
                                                    <Grid item xs={12} md={12}>
                                                        <CustomTypography variant="span" text={`${item.nutrients[0].carb} ${t('item.nutrients.carbAfterLabel')}`} />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                }

                                <Grid item xs={12} md={12} sx={textAlignLeft()}>
                                    {item.allergies?.length > 0 &&
                                        <>
                                            <Divider light flexItem sx={dividerStyle()} />

                                            <Grid item xs={12} md={12} sx={{ marginTop: "20px" }}>
                                                <Grid container gap={1}>
                                                    <Grid item xs={12} md={12}>
                                                        <CustomTypography
                                                            variant="body2"
                                                            text={t('item.allergies')}
                                                            sx={menuItemHeaderTextStyle({ fontSize: menuConfiguration?.itemHeaderFontSize, color: menuConfiguration?.itemHeaderColor })}
                                                        />
                                                    </Grid>

                                                    <Grid item xs={12} md={12}>
                                                        {getAllergies(item.allergies)}
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </>
                                    }
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} sm={12}>
                            <Divider light flexItem sx={dividerStyle()} />
                        </Grid>

                        {(item.modifierLists?.length > 0 && !isMobile) &&
                            <Grid item xs={12} sm={12}>
                                {item.modifierLists?.map((modifierList) =>
                                    <>
                                        {modifierList.modifiers?.length > 0 &&
                                            <Grid container sx={{ ...textAlignLeft(), marginBottom: "20px", paddingLeft: "20px" }}>
                                                <Grid item xs={12} sm={12}>
                                                    <CustomTypography
                                                        variant="body2"
                                                        text={modifierList.name}
                                                        sx={menuItemHeaderTextStyle({ fontSize: menuConfiguration?.itemHeaderFontSize, color: menuConfiguration?.itemHeaderColor })}
                                                    />
                                                    <CustomTypography
                                                        variant="body2"
                                                        text={prepareTextChip("", modifierList)}
                                                        sx={menuItemDescriptionHeaderTextStyle({ fontSize: menuConfiguration?.itemFontSize, color: menuConfiguration?.itemColor })}
                                                    />
                                                </Grid>

                                                <Grid item xs={12} sm={12}>
                                                    {!modifierList.allowMultipleSelection &&
                                                        <FormControl component="fieldset" key={modifierList.id}>
                                                            <RadioGroup
                                                                name={modifierList.id}
                                                                value={cartItem.modifierListIds.find(ml => ml.modifierListId === modifierList.id)?.modifiers[0].id || ''}
                                                                onChange={(event) => handleSingleSelectionChange(modifierList.id, event.target.value)}
                                                            >
                                                                <Grid container sx={{ ...textAlignLeft(), marginBottom: "20px" }}>
                                                                    {modifierList.modifiers?.map((modifier) =>
                                                                        <Grid item xs={6} sm={6}>
                                                                            <FormControlLabel
                                                                                value={modifier.id}
                                                                                control={<Radio
                                                                                    sx={{
                                                                                        '&.Mui-checked': {
                                                                                            color: menuConfiguration?.itemHeaderColor, // Change this to your desired color
                                                                                        },
                                                                                        fontSize: menuConfiguration?.itemHeaderFontSize
                                                                                    }} />
                                                                                }
                                                                                label={
                                                                                    <CustomTypography
                                                                                        variant="span"
                                                                                        text={modifier.name}
                                                                                        sx={menuItemDescriptionHeaderTextStyle({ fontSize: menuConfiguration?.itemDescFontSize })}
                                                                                    />
                                                                                }
                                                                            />
                                                                        </Grid>
                                                                    )}
                                                                </Grid>
                                                            </RadioGroup>
                                                        </FormControl>
                                                    }

                                                    {modifierList.allowMultipleSelection &&
                                                        <FormGroup>
                                                            <Grid container>
                                                                {modifierList.modifiers?.map((modifier) =>
                                                                    <Grid item xs={6} sm={6}>
                                                                        <FormControlLabel
                                                                            control={
                                                                                <Checkbox
                                                                                    checked={cartItem.modifierListIds.find(ml => ml.modifierListId === modifierList.id)?.modifiers.some(mod => mod.id === modifier.id)}
                                                                                    onChange={() => {
                                                                                        handleMultipleSelectionChange(modifierList.id, modifier.id, modifierList.modifiers);
                                                                                    }}
                                                                                    sx={{
                                                                                        '&.Mui-checked': {
                                                                                            color: menuConfiguration?.itemHeaderColor, // Change this to your desired color
                                                                                        },
                                                                                        fontSize: menuConfiguration?.itemHeaderFontSize
                                                                                    }}
                                                                                />}
                                                                            label={
                                                                                <CustomTypography
                                                                                    variant="span"
                                                                                    text={modifier.name}
                                                                                    sx={menuItemDescriptionHeaderTextStyle({ fontSize: menuConfiguration?.itemDescFontSize })}
                                                                                />}
                                                                        />
                                                                    </Grid>
                                                                )}
                                                            </Grid>
                                                        </FormGroup>
                                                    }
                                                </Grid>
                                            </Grid>
                                        }
                                    </>
                                )}
                            </Grid>
                        }

                        {(item.modifierLists?.length > 0 && isMobile) &&
                            <Grid item xs={12} sm={12}>
                                {item.modifierLists?.map((modifierList) =>
                                    <>
                                        {modifierList.modifiers?.length > 0 &&
                                            <Grid container
                                                sx={{ ...textAlignLeft(), width: "100%", margin: "10px 0", padding: "10px", borderRadius: "10px", background: menuConfiguration?.itemBackgroundColor }}
                                                onClick={() => {
                                                    handleModifiersDialogState(true, modifierList);
                                                }}
                                            >
                                                <Grid item xs={11} sm={11}>
                                                    <CustomTypography
                                                        variant="body2"
                                                        text={modifierList.name}
                                                        sx={menuItemHeaderTextStyle({ fontSize: menuConfiguration?.itemHeaderFontSize, color: menuConfiguration?.itemHeaderColor })}
                                                    />

                                                    <CustomTypography
                                                        variant="body2"
                                                        text={prepareText(modifierList)}
                                                        sx={menuItemDescriptionHeaderTextStyle({ fontSize: menuConfiguration?.itemFontSize, color: menuConfiguration?.itemColor })}
                                                    />
                                                </Grid>

                                                <Grid item xs={1} sm={1}>
                                                    <ArrowRightIcon
                                                        onClick={() => {
                                                            handleModifiersDialogState(true, modifierList);
                                                        }}
                                                        sx={{ fontSize: "34px", color: menuConfiguration?.itemHeaderColor }}
                                                    />
                                                </Grid>
                                            </Grid>
                                        }
                                    </>
                                )}
                            </Grid>
                        }

                        {outletAllowComments && menuConfiguration?.allowCommentsOnItem &&
                            <>
                                <Grid item xs={12} sm={12} sx={{ padding: "10px", background: "#efefef" }}>
                                    <Grid container>
                                        <Grid item xs={12} sm={12} sx={textAlignLeft()}>
                                            <CustomTypography
                                                variant="body2"
                                                text={t('item.notes')}
                                                sx={menuItemHeaderTextStyle({ fontSize: menuConfiguration?.itemHeaderFontSize, color: menuConfiguration?.itemHeaderColor })}
                                            />
                                        </Grid>

                                        <Grid item xs={12} sm={12}>
                                            <CustomTextArea
                                                placeholder={t('item.notesInfo')}
                                                // variant="outlined"
                                                value={cartItem.notes}
                                                inputProps={{ maxLength: 100 }}
                                                maxLength={100}
                                                onChange={(event) => {
                                                    let newValue = event.target.value.slice(0, 100);
                                                    formValChangeWithParentElementWithNameAndValue("notes", newValue, cartItem, setCartItem);
                                                }}
                                                sx={{ borderColor: "lightgrey" }}
                                                endDecorator={
                                                    <Box sx={requestTextareaBoxStyle()}>
                                                        <Divider flexItem={true} />
                                                        <Typography level="span" sx={{ ml: 'auto', color: cartItem.notes?.length === 100 ? "red" : "initial" }}>
                                                            {`${cartItem.notes?.length} ${cartItem.notes?.length === 1 ? t('item.itemCharLimit') : t('item.itemCharsLimit')} /${requestTextFieldLimit}`}
                                                        </Typography>
                                                    </Box>
                                                }
                                            />

                                            {/* <TextareaAutosize
                                                placeholder={t('item.notesInfo')}
                                                // variant="outlined"
                                                value={cartItem.notes}
                                                inputProps={{ maxLength: 100 }}
                                                maxLength={100}
                                                onChange={(event) => {
                                                    let newValue = event.target.value.slice(0, 100);
                                                    formValChangeWithParentElementWithNameAndValue("notes", newValue, cartItem, setCartItem);
                                                }}
                                                style={{ borderColor: "lightgrey", width: "95%", padding: "10px", borderRadius: "10px", marginTop: "10px" }}
                                                endDecorator={
                                                    <Box sx={requestTextareaBoxStyle()}>
                                                        <Divider flexItem={true} />
                                                        <Typography level="span" sx={{ ml: 'auto', color: cartItem.notes.length === 100 ? "red" : "initial" }}>
                                                            {`${cartItem.notes.length} ${cartItem.notes.length === 1 ? t('item.itemCharLimit') : t('item.itemCharsLimit')} /${requestTextFieldLimit}`}
                                                        </Typography>
                                                    </Box>
                                                }
                                            /> */}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </>
                        }

                        {enableCart &&
                            <Grid item xs={12} sm={12}>
                                <Grid container>
                                    <Grid item xs={12} sm={12}>
                                        <Divider light flexItem sx={{ ...dividerStyle(), margin: 0 }} />
                                    </Grid>

                                    <Grid item xs={12} sm={12}>
                                        <Grid container sx={{ padding: "10px 10px 0 10px" }}>
                                            <Grid item xs={5} sm={7} sx={{ textAlign: "left" }}>
                                                <GroupedItemButtons counter={cartItem.quantity} handleIncrement={handleIncrement} handleDecrement={handleDecrement} />
                                            </Grid>
                                            <Grid item xs={2}>
                                                {(item?.available && item?.sortage < 5 && item?.sortage > 1) &&
                                                    <Tooltip title={t("checkout.availableTooltip")}>
                                                        <Badge badgeContent={item?.sortage?.length} color="primary" sx={{ marginBottom: "50px" }} />
                                                    </Tooltip>
                                                }
                                            </Grid>
                                            <Grid item xs={5} sm={5} sx={{ textAlign: "right" }}>
                                                {/* ADD TO CART */}
                                                <CustomIconButton
                                                    variant="outlined"
                                                    sx={
                                                        menuItemButtonStyle(
                                                            menuConfiguration?.buttonBackgroundColor,
                                                            menuConfiguration?.buttonFont,
                                                            menuConfiguration?.buttonColor,
                                                            menuConfiguration?.buttonFontSize
                                                        )}
                                                    onClick={() => { handleOpen(false); action(cartItem, modifiersPrice) }}
                                                    label={(cartIndex !== null && cartIndex !== -1) ? t('actions.update') : t('menus.dialog.buttons.addToCart')}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        }
                    </>
                }

                {dialog.isOpen &&
                    <CustomSwippableDrawer
                        open={dialog.isOpen}
                        onClose={() => handleModifiersDialogState(false, null)}
                        onOpen={() => handleModifiersDialogState(true, dialog.modifierList)}
                        anchor={"bottom"}
                        paperSx={{ ...autDrawerPaperStyle(), padding: dialog.modifierList !== null ? "0 0 10px 0" : "initial" }}
                    >
                        {dialog?.modifierList?.modifiers?.length > 0 &&
                            <Grid container sx={{ ...textAlignLeft(), marginBottom: "20px", paddingLeft: "20px" }}>
                                <Grid item xs={12} sm={12}>
                                    <CustomTypography
                                        variant="body2"
                                        text={dialog.modifierList.name}
                                        sx={menuItemHeaderTextStyle({ fontSize: menuConfiguration?.itemHeaderFontSize, color: menuConfiguration?.itemHeaderColor })}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={12}>
                                    {!dialog.modifierList.allowMultipleSelection &&
                                        <FormControl component="fieldset" key={dialog.modifierList.id}>
                                            <RadioGroup
                                                name={dialog.modifierList.id}
                                                value={cartItem.modifierListIds.find(ml => ml.modifierListId === dialog.modifierList.id)?.modifiers[0].id || ''}
                                                onChange={(event) => handleSingleSelectionChange(dialog.modifierList.id, event.target.value)}
                                            >
                                                <Grid container sx={{ ...textAlignLeft(), marginBottom: "20px" }}>
                                                    {dialog.modifierList.modifiers?.map((modifier) =>
                                                        <Grid item xs={12} sm={12}>
                                                            <FormControlLabel
                                                                value={modifier.id}
                                                                control={<Radio
                                                                    sx={{
                                                                        '&.Mui-checked': {
                                                                            color: menuConfiguration?.itemHeaderColor, // Change this to your desired color
                                                                        },
                                                                        fontSize: menuConfiguration?.itemHeaderFontSize
                                                                    }} />
                                                                }
                                                                label={
                                                                    <CustomTypography
                                                                        variant="span"
                                                                        text={modifier.name}
                                                                        sx={menuItemDescriptionHeaderTextStyle({ fontSize: menuConfiguration?.itemDescFontSize })}
                                                                    />
                                                                }
                                                            />
                                                        </Grid>
                                                    )}
                                                </Grid>
                                            </RadioGroup>
                                        </FormControl>
                                    }

                                    {dialog.modifierList?.allowMultipleSelection &&
                                        <>

                                            <Grid item xs={12} sm={12}>
                                                <CustomTypography
                                                    variant="body2"
                                                    text={modifierListInvalid?.find((modifierListInv) => modifierListInv.id === dialog?.modifierList?.id)?.invalidText}
                                                    sx={menuItemHeaderTextStyle({ fontSize: menuConfiguration?.itemHeaderFontSize, color: "red" })}
                                                />
                                            </Grid>

                                            {prepareTextChip("", dialog.modifierList)}

                                            <FormGroup>
                                                <Grid container>
                                                    {dialog.modifierList.modifiers?.map((modifier) =>
                                                        <Grid item xs={12} sm={12}>
                                                            <FormControlLabel
                                                                control={
                                                                    <Checkbox
                                                                        checked={cartItem.modifierListIds.find(ml => ml.modifierListId === dialog.modifierList.id)?.modifiers.some(mod => mod.id === modifier.id)}
                                                                        onChange={() => {
                                                                            handleMultipleSelectionChange(dialog.modifierList.id, modifier.id, dialog.modifierList.modifiers);
                                                                        }}
                                                                        sx={{
                                                                            '&.Mui-checked': {
                                                                                color: menuConfiguration?.itemHeaderColor, // Change this to your desired color
                                                                            },
                                                                            fontSize: menuConfiguration?.itemHeaderFontSize
                                                                        }}
                                                                    />}
                                                                label={
                                                                    <CustomTypography
                                                                        variant="span"
                                                                        text={modifier.name}
                                                                        sx={menuItemDescriptionHeaderTextStyle({ fontSize: menuConfiguration?.itemDescFontSize })}
                                                                    />}
                                                            />
                                                        </Grid>
                                                    )}
                                                </Grid>
                                            </FormGroup>
                                        </>
                                    }
                                </Grid>

                                <Grid item xs={12} md={12} sx={{ marginTop: "30px" }}>
                                    <Button
                                        variant="outlined"
                                        onClick={() => handleModifiersDialogState(false, null)}
                                        sx={{
                                            borderRadius: "10px",
                                            right: 10,
                                            bottom: 10,
                                            position: "fixed",
                                            borderColor: menuConfiguration?.itemHeaderColor
                                        }}>
                                        <Stack direction="row" spacing={2} sx={cartButtonStackStyle()}>
                                            <ItemPaper sx={{ ...cartButtonLabelItemStyle(), color: menuConfiguration?.itemHeaderColor }}>
                                                {t('item.ok')}
                                            </ItemPaper>
                                        </Stack>
                                    </Button>
                                </Grid>
                            </Grid>
                        }
                    </CustomSwippableDrawer>
                }
            </Grid>
        );
    }
}

export default CustomItemCard;